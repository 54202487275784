@import "../../../../assets/styles/theme";


.action {
  display: flex;
  flex-direction: column;
  padding: 3.2rem 4rem;

  .quantity {
    margin-bottom: 2rem;

    p {
      margin-bottom: 0.9rem;
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: bold;
      color: $black-color;
      text-transform: uppercase;
    }

    input[type="number"] {
        font-size: $small-base-font-size;
        color: $input-text-color-gray;
    }
  }

  .price {
    .title {
      margin-bottom: 0.9rem;
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: bold;
      color: $black-color;
      text-transform: uppercase;
    }

    .value {
      font-family: Montserrat, sans-serif;
      font-size: $xl-font-size;
      font-weight: 600;
      color: $info-color;
    }
  }
}
