@import "../../../../../assets/styles/theme";


.countryList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: center;

  .country {
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba($black-color, 0.1);
    background-color: $white-color;
    width: 22rem;
    min-height: 15rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;

      .countryName {
        color: $info-color;
        text-shadow: -0.39rem 0.52rem 1.35rem rgba($black-color, 0.15);
      }
    }

    .countryFlag {
      width: 6rem;
      height: 4rem;
      margin-bottom: 1rem;

      img {
         box-shadow: 0 0 1.5rem 0 rgba($black-color, 0.1);
      }
    }

    .countryName {
      text-align: center;
      color: $black-color;
      font-family: Montserrat, sans-serif;
      font-size: $base-m-font-size;
      font-weight: 600;
      line-height: 1.7;
      text-shadow: -0.78rem 1.04rem 2.7rem rgba($black-color, 0.15);
      letter-spacing: 0.075rem;
    }
  }
}
