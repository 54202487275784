@import "../../../../assets/styles/theme";


.actionsContainer {
  .actionBtn {
    background-color: transparent;
    outline: none;
    font-size: $base-font-size;
    height: 2.8rem;
    width: 2.5rem;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .2s;

    &.actionFavorite {
      margin-right: 2rem;
      background-image: url("../../../../assets/images/heart_off.svg");

      &.active {
        background-image: url("../../../../assets/images/heart_on.svg");
      }
    }

    &.actionApplication {
      background-image: url("../../../../assets/images/star_off.svg");

      &.active {
        background-image: url("../../../../assets/images/star_on.svg");
      }
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
