@import "../../../../../assets/styles/theme";


.realmList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: center;

  .realm {
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba($black-color, 0.1);
    background-color: $white-color;
    width: 25rem;
    min-height: 12rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;

      .realmName {
        color: $info-color;
        text-shadow: -0.39rem 0.52rem 1.35rem rgba($black-color, 0.15);
      }
    }

    .realmIcon, img {
      width: 5.4rem;
      height: 5.4rem;
      border: 0.1rem solid $input-border-color;
      margin-bottom: 0.2rem;
    }

    img {
      border: none;
    }

    .realmName {
      text-align: center;
      color: $black-color;
      font-family: Montserrat, sans-serif;
      font-size: $base-m-font-size;
      font-weight: 600;
      line-height: 1.25;
      text-shadow: -0.78rem 1.04rem 2.7rem rgba($black-color, 0.15);
      letter-spacing: normal;
    }
  }
}
