@import "../../../../assets/styles/theme";

.details {
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 0.1rem solid $input-border-color;

  .img {
    width: 20rem;
    padding-top: 15%;
    background-color: $input-border-color;
    margin-right: 2.4rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .testTitle {
    font-family: Montserrat, sans-serif;
    font-size: $xm-font-size;
    font-weight: 600;
    color: $black-color;
  }
  .serviceAdditionalInformation {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: $base-font-size;
      padding: 0 1rem;
      color: $black-color;
  }
}

@media (max-width: $breakpoint-xxlarge) {
  .details {
    border: none;
  }
}
