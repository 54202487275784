@import "../../../assets/styles/theme";

.resultList {
  list-style: none;
  display: flex;
  flex-direction: column;

  .resultListItem {
    margin: 0.5rem 0;
    padding: 0.8rem 1.5rem;
    display: flex;
    flex: 1;
    background-color: $school-form-background;
    font-size: $small-font-size;
    font-weight: 500;
    align-items: center;

    .resultItemName {
      flex: 6;
      font-size: $base-m-font-size;
      font-weight: 600;
      text-decoration: none;
    }

    .iconWrapper {
      display: flex;
      flex: 1;

      .resultIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.7rem;
        border-radius: 50%;
        color: $white-color;

        i {
          font-size: $m-font-size;
        }

        &.passed {
          background-color: $success-color;
        }

        &.failed {
          background-color: $failure-color;
        }
      }
    }

    .pointsWrapper {
      flex: 3;
      text-align: center;

      .pointsAchieved {
        font-size: $xll-font-size;
        font-weight: 600;
      }
    }
  }
}
