@import "../../../../assets/styles/theme";


.packageStudyFields {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;

  .title {
    margin-bottom: 0.9rem;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: bold;
    color: $black-color;
    text-transform: uppercase;
  }
}
