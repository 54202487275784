.section {
    &.competence {
        background: url("../images/patches.png") no-repeat right 15rem;

        .custom-card {
            .custom-card-title {
                .subheader {
                    &.competence {
                        padding: 0;
                        font-size: $ml-font-size;
                    }
                }
            }

            .competence-btn-container {
                display: flex;
                justify-content: center;
                margin-top: 1rem;

                &.details-btn-container {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    &.morrisby-test {
        background: url("../images/patches.png") no-repeat center right;

        .morrisby-test-wrapper {
            padding: 0 0.75rem;
        }

        .morrisby-section-list {
            list-style: none;

            li {
                margin: 1rem 0 0 0.7rem;
                line-height: 1.87;

                &:before {
                    content: "\2022";
                    color: $info-color;
                    font-weight: bold;
                    display: inline-block;
                    width: 1rem;
                    margin-left: -1rem;
                }
            }
        }
    }

    &.competence-test {
        .competence-form-wrapper {
            background-color: $auth-form-background;
            .view-subtitle {
                padding: 3rem;
                margin: 0;
            }

            .form-label {
                display: flex;
                align-items: flex-start;

                .auto-rich-content {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: flex-start;
                    justify-items: center;

                    .auto-rich-image {
                        margin: 0 1rem;
                    }
                }
            }

            .radio-control {
                flex: 1;
                display: flex;
                align-items: stretch;
                .radio-wrapper {
                    display: flex;
                    flex: 1;
                    align-items: stretch;
                    flex-wrap: wrap;
                    margin: 0.5rem;

                    .radio-input,
                    .radio-checkmark {
                        display: none;
                    }

                    .radio-label {
                        margin: 0 0.3rem 0.3rem 0;
                        padding: 0.9rem 1.2rem;
                        border: 0.2rem solid $input-border-color;
                        border-radius: 2rem;
                        color: $input-text-color-gray;
                        background: $white-color;
                        font-size: $small-base-font-size;
                        font-weight: 500;
                        white-space: pre-wrap;
                        letter-spacing: normal;
                        display: flex;
                        align-items: center;

                        &:hover {
                            cursor: pointer;
                        }

                        .auto-rich-content {
                            display: flex;
                            align-content: center;
                            align-items: center;
                            justify-content: center;
                            justify-items: center;

                            .auto-rich-image {
                                margin: 0 1rem;
                            }
                        }
                    }

                    .radio-input:checked ~ .radio-label {
                        background: $info-color;
                        color: $white-color;
                        border: 0.2rem solid $info-color;
                    }
                }
            }
        }
        .test-timer {
            font-size: $xxl-font-size;
            font-weight: 800;
            color: $black-color;
            padding: 0 4rem 2rem;
        }
    }
    &.competence-test-result {
        .header-section {
            .header-subtitle {
                .highlight {
                    font-weight: 800;
                    font-size: $xll-font-size;
                }
            }
            .header-description {
                font-size: $ml-font-size;
                display: flex;
                flex-direction: column;
                .highlighted-rating {
                    font-size: $xl-font-size;
                    font-weight: 800;
                    color: $info-color;
                }
            }
        }
        .custom-details {
            .custom-details-title {
                &::after {
                    top: calc(50% - 0.8rem);
                }
                .question-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 3rem;
                    .question-name {
                        display: flex;
                        flex: 1;
                    }
                    .question-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0.7rem;
                        border-radius: 50%;
                        color: $white-color;
                        max-width: 3rem;
                        i {
                            font-size: $m-font-size;
                        }
                        &.correct {
                            background-color: $success-color;
                        }
                        &.incorrect {
                            background-color: $failure-color;
                        }
                    }
                }
            }
            .question-solution {
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 0.5rem;
            }

            .auto-rich-content {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: flex-start;
                justify-items: center;

                .auto-rich-image {
                    margin: 0 1rem;
                }
            }
        }

        .answer-list {
            list-style: none;
            display: flex;
            .answer-item {
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 1rem;
                background: $light-color;
                position: relative;
                font-size: $small-font-size;
                margin: 0.5rem;

                &.selected {
                    border: 0.5rem solid $info-color;
                }

                &.correct {
                    color: $white-color;
                    background-color: $theme-second-color;
                }

                .answer-title {
                    font-weight: 600;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .rating-description-list {
        list-style-type: none;
        .rating-description-item {
            padding: 1rem;
            border: 0.1rem solid $border-color;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            &.current {
                border: 0.2rem solid $info-color;
            }
            .rating-description-rating {
                font-size: $m-font-size;
                font-weight: 600;
            }
        }
    }
    .test-section-list {
        list-style-type: none;
        .test-section-item {
            border-radius: 0.4rem;
            border: 0.1rem solid $border-color;
            background-color: $light-color;
            margin-bottom: 1rem;
            cursor: pointer;
            .test-section-link {
                display: flex;
                flex: 1;
                padding: 1rem;
                text-decoration: none;
            }
            &.active {
                color: $white-color;
                border-color: $info-color;
                background-color: $info-color;
            }
        }
    }
}
