@import "../../../../../assets/styles/theme";

.fieldOfStudyListItemWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 2.8rem 3.2rem;
  margin: 1rem 0;
  border-radius: 0.6rem;
  box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  position: relative;

  &.removeAnimation {
    animation: remove-animation 2s;
    animation-iteration-count: 1;
    transform-origin: top;
    max-height: 22rem;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 16rem;

    .fieldOfStudyImage {
      max-height: 15rem;
      max-width: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
  }

  .fieldOfStudyDetailsWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    .fieldOfStudyCategory {
      text-transform: uppercase;
      font-size: $base-m-font-size;
      color: $info-color;
      margin-bottom: 2rem;
      font-weight: 600;
    }

    .fieldOfStudyTitle {
      color: $black-color;
      font-weight: 600;
      font-size: $ml-font-size;
      margin-bottom: 2rem;
    }

    .fieldOfStudyInformationWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: $base-font-size;
      color: $black-color;
      font-weight: 500;

      .markerIcon {
        font-size: $base-font-size;
        margin-right: 0.25rem;
      }

      .details {
        margin-bottom: 2rem;

        span {
          &:not(:first-child) {
            margin-left: 2rem;
            position: relative;

            &::after {
              position: absolute;
              left: -1.15rem;
              top: calc(50% - 0.25rem);
              content: "";
              height: 0.5rem;
              width: 0.5rem;
              border-radius: 50%;
              background-color: $datepicker-header-color;
            }
          }
        }
      }
    }

    .price {
      color: $black-color;
      display: flex;
      align-items: flex-end;
      font-weight: 600;

      .priceValue {
        font-weight: 600;
        font-size: $ml-font-size;
        margin-right: 1rem;
      }
    }
  }

  .fieldOfStudyActionButtonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .rankWrapper {
      align-items: flex-end;
    }
  }
}

@keyframes remove-animation {
  from {
    opacity: 1;
    max-height: 22rem;
  }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0 3.2rem;
    margin: 0;
  }
}
