@import "../../../assets/styles/theme";

.mentorDetailsContainer {
  min-width: 12rem;
  display: inline-grid;

  .mentorDetails {
    max-width: 20rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    .itemImgContainer {
      min-width: 18rem;
      max-width: 18rem;
      min-height: 18rem;
      max-height: 18rem;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      border: thin solid $border-color;
      overflow: hidden;
      margin-bottom: 1.6rem;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .name {
        font-family: Montserrat, sans-serif;
        font-size: $base-m-font-size;
        font-weight: 600;
        text-align: center;
        color: $black-color;
      }
    }
  }
}


@media (max-width: $breakpoint-full) {
  .mentorDetailsContainer {
    .mentorDetails {
      .itemImgContainer {
        min-width: 15rem;
        max-width: 15rem;
        min-height: 15rem;
        max-height: 15rem;
      }
    }
  }
}
@media (max-width: $breakpoint-xxlarge) {
  .mentorDetailsContainer {
    .mentorDetails {
      min-width: 12rem;

      .itemImgContainer {
        min-width: 12rem;
        max-width: 12rem;
        min-height: 12rem;
        max-height: 12rem;
      }
    }
  }
}

@media (max-width: $breakpoint-xlarge) {
  .mentorDetailsContainer {
    .mentorDetails {
      min-width: 8rem;
    }
  }
}
