@import "../../../../assets/styles/theme";

.closeModal {
  display: flex;
  justify-content: flex-end;

  button {
    background: transparent;
    color: rgba($black-color, 0.4);

    &:hover {
      color: $primary-color;
    }
  }
}

.notificationModalTitle {
  margin-bottom: 1.7rem;
  text-shadow: 0.44rem 0.48rem 1.35rem rgba($black-color, 0.15);
  font-family: Montserrat, sans-serif;
  font-size: $xxl-font-size;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.031rem;
  color: $black-color;
}

.description {
  font-weight: 500;
  margin-bottom: 2rem;
  color: $black-color;
  font-size: $m-font-size;
  min-height: 1.7rem;
  text-align: center;
}

