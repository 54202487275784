@import "../../../assets/styles/theme";

.profileInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .profileInfoDetails {
    color: $black-color;
    font-size: $base-ms-font-size;
    font-weight: 500;
    letter-spacing: 0.045rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    .profileTitle {
      font-size: $xl-font-size;
      letter-spacing: 0.032rem;
      color: $username-color;
      margin-bottom: 1.9rem;
    }

    .profileMail {
      color: $info-color;
      margin-bottom: 0.5rem;
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 500;
      letter-spacing: 0.45px;
    }

    .controlWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      flex-direction: column;
      width: 100%;

      .control {
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px transparent;
        padding: 0.55rem 0.6rem 0.4rem 0.8rem;
        width: 100%;
        flex: 1;

        .inputWrapper {
          display: flex;

          .input {
            width: 100%;
            font-family: Montserrat, sans-serif;
            font-size: $base-font-size;
            font-weight: 500;
            border: none;
            background-color: #fff;
            text-align: center;

            &:disabled {
              background-color: $white-color;
              letter-spacing: 0.45px;
              text-align: center;
              color: $black-color;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .btnContainer {
          display: flex;
        }

        button {
          height: 100%;
          padding: 0 0.5rem;
          background-color: transparent;

          i {
          }
        }

        &.editable {
          border-radius: 2px;
          border: solid 1px #d3d3d3;
        }

        &.error {
          border-color: $danger-color;
        }
      }

      .errorMessage {
        color: $danger-color;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: $breakpoint-xlarge) {
}
