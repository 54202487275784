@import "~bootstrap/scss/bootstrap";
@import "font/feather";

@import "~educat-styles/normalize";
@import "~educat-styles/panel/theme-variables";
@import "~educat-styles/panel/style";
@import "~educat-styles/panel/form-controls/style";
@import "menu-icons";
@import "auth";
@import "content-files";
@import "application";
@import "payments";
@import "competence";
@import "form";

.basic-multi-select {
	width: 100%;
}

// react stars component custom stars
.star-icon {
	width: 1.8rem;
	height: 1.8rem;
	background-size: cover;
	display: flex;
	&.full {
		background-image: url(../../assets/images/star_full.svg);
	}
	&.empty {
		background-image: url(../../assets/images/star_empty.svg);
	}
}
// common rank wrapper styles
.rank-wrapper {
	display: flex;
	flex-direction: column;
	color: $black-color;

	.rank-number {
		font-weight: 600;
		font-size: $ml-font-size;
		.rank-place {
			margin-right: 0.5rem;
		}
	}

	.rank-text {
		color: $black-color;
		text-align: right;
		white-space: nowrap;
		font-size: $small-font-size;
		font-weight: 500;
	}
}

// common button wrapper - buttons pushed to end by default
.button-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	&.centered {
		justify-content: center;
	}
	&.start-align {
		justify-content: flex-start;
	}
}

// common main title styling

.secondary-title {
	text-shadow: -0.8rem 1rem 2.7rem rgba(0, 0, 0, 0.15);
	margin-bottom: 1.4rem;
	font-family: "Montserrat", sans-serif;
	font-size: $ml-font-size;
	font-weight: 500;
	letter-spacing: 0.25px;
	color: $black-color;
	&.big-margin {
		margin-bottom: 2.4rem;
	}
}

// overwrite color of subject-item
.subjects {
	.subject-item {
		background-color: $info-color;
		color: $white-color;
		border: none;
		box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
	}
}
// applicant profile user image styles:
.applicant-survey,
.applicant-profile-section {
	background: url("../images/patches.png") no-repeat right 35rem;

	.user-img {
		margin-bottom: 4rem;
	}
	.profile-form {
		.form-row {
			&.no-option-checkbox {
				.checkbox-container {
					padding: 0;
					flex: 1;
					justify-content: flex-end;
					.checkbox-wrapper {
						display: inline-flex;
						flex-direction: row-reverse;
						justify-content: flex-end;
						width: unset;
						height: unset;
						.checkbox-label {
							margin-left: 3.1rem;
							text-transform: uppercase;
						}
						.checkbox-input, .checkbox-checkmark {
							width: 1.6rem;
							height: 1.6rem;
						}
						.checkbox-checkmark {
							&::after {
								width: 1.5rem;
								height: 1.3rem;
							}
						}
					}
				}
			}
		}
	}
}

// cart modal calendar styling
.cart-wrapper {
    .modal-wrapper {
        &.custom {
            justify-content: flex-start;
        }
    }
}

.blue-border {
	border: 1px $primary-color solid;
}

.bg-white {
	background: white;
}

.payment-method {

	border-radius: 5px;
	background: rgba($white, 0.75);

	//input[type=radio] {
	//	border: 0px;

	//}

	input {
		appearance: none;

		border-radius: 50%;
		width: 1.5em;
		height: 1.5em;

		border: 2px solid $primary-color;
		//transition: 0.2s all linear;
		margin-right: 5px;

		//position: relative;
		top: 4px;
	}

	input:checked {
		background: $primary-color;
	}

	.content {
		border: 1px $primary-color solid;
	}

	.title {
		padding: 1rem 1rem 1rem 1rem;
		background-color: $primary-color;
		color: white;
		text-align: center;
		font-weight: bold;
	}
}


// marketplace
.marketplace-mentor-select-form {
	background-color: rgb(246, 249, 252);
	border-radius: 0.6rem;
	padding: 2rem;
	width: 100%;
	margin: 0;
}

// basic status bagde
.default-status-badge {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
    border-radius: 2rem;
    background-color: $white-color;
    color: rgba($black-color, 0.6);
    margin: 0 0.25rem 0.25rem 0;
    font-weight: 500;
    border: 0.2rem solid $input-border-color;
    width: 100%;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    .mobile-hidden {
      display: none;
    }
}

.modal-content-container {
	&.edit-insurance-modal {
		max-height: 90%;
		overflow-y: scroll;

		.custom-card {
			.custom-card-header {
				.custom-card-title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 1rem;

					button {
						background-color: transparent;
					}
				}
			}
		}
	}
}

.insurances-table {
	.tooltip-wrapper {
		.tooltip-content {
			width: auto;
		}
	}
}

.insurance-data-form {
	.react-datepicker-host {
		.react-datepicker-wrapper {
			.react-datepicker__input-container {
				&:after {
					padding-left: 3rem;
					top: calc(50% - 1rem);
					right: 0.5rem;
					height: 2rem;
					border-left: 0.2rem solid $input-border-color;
					background: url('../../assets/images/calendar.svg') center no-repeat;
				}
			}

			&.focused {
				.react-datepicker__input-container {
					&:after {
						background: url('../../assets/images/calendar-active.svg') center no-repeat;
					}
				}
			}
		}
	}
}