@import "../../assets/styles/theme";

.filterContainer {
  z-index: 9999;
  background-color: white;
  position: fixed;
  left: 0;
  top: 44rem;
  bottom: 0;
  width: 33rem;
  padding: 1.5rem 2rem 0;
  box-shadow: 0 0 2.75rem 0 rgba(0, 0, 0, 0.05);
  overflow-y: auto;
}

@media (max-width: $breakpoint-medium) {
    .filterContainer {
      z-index: 1;
      width: unset;
      position: unset;
    }
  }
