@import "../../../../assets/styles/theme";

.utilButtonsWrapper {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    margin: 1rem 0;
    gap: 2rem;

    .utilButton {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem 2.9rem;
        background-color: $white-color;
        border: thin solid transparent;
        border-radius: 0.6rem;
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
        text-decoration: none;
        position: relative;

        &:hover {
            border: thin solid $input-border-color;
        }

        &::after {
            position: absolute;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            right: 2.9rem;
            top: calc(50% - 0.9rem);
            opacity: 0.6;
            content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAMAAACX3symAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAHVQTFRFAAAAu7u7kJCQxsbGf39/wMDAioqKxMTE4+Pjh4eHx8fH4eHhhYWFgICAysrK39/fhISEzc3N3Nzcg4OD0NDQ2tragoKC4uLigYGB5OTk5ubmwsLC6OjoiYmJv7+/6urqvLy8j4+Pubm57u7ut7e3sbGxhoaGmCkMhQAAACd0Uk5TAP/////////A///g///////////////Q/7CQ/3D//1D///8Q////QxSaywAAAGVJREFUeJyFzzcSgDAMBEADImdMzpjw/yciF7ZcMMN1W5zmxNhfLNsxCQCuQQ/tE4MQHZHjBCDNyHmBLskVR9fkpgXgxA7rvdYgj+sxo9SkNC+oVWmTs3ZdPFBC60Rdxgv3I9h3XqlwA+zdOV25AAAAAElFTkSuQmCC);
        }

        .buttonIcon {
            font-size: $xl-font-size;
            color: $gray-text-color;
            margin-right: 2rem;
            font-weight: 800;

            &.activeStarIcon {
                background-image: url("../../../../assets/images/active_star.svg");
                width: 3.1rem;
                height: 2.9rem;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &.activeHeartIcon {
                background-image: url("../../../../assets/images/heart_on.svg");
                width: 3.2rem;
                height: 2.8rem;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        .buttonText {
            font-size: $m-font-size;
            font-weight: 600;
            margin: 0 1rem;
            color: $black-color;

            .counter {
                font-size: $xxs-font-size;
                color: $datepicker-text-color;
                margin-left: 0.5rem;
                display: inline-flex;
                vertical-align: middle;
            }
        }
    }
}
