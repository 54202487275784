@import "../../../../assets/styles/theme";

.closeModal {
  display: flex;
  justify-content: flex-end;

  button {
    background: transparent;
    color: rgba($black-color, 0.4);

    &:hover {
      color: $primary-color;
    }
  }
}

.paymentTitle {
  margin-bottom: 1.7rem;
  text-shadow: 0.44rem 0.48rem 1.35rem rgba($black-color, 0.15);
  font-family: Montserrat, sans-serif;
  font-size: $ml-font-size;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.031rem;
  color: $black-color;
}

.summaryTitle {
  font-weight: 700;
  margin-bottom: 0.8rem;
  color: $black-color;
  font-size: $base-ms-font-size;
  min-height: 1.7rem;
}

.summaryPaid {
  font-weight: 500;
  margin-bottom: 2rem;
  color: $black-color;
  font-size: $m-font-size;
  min-height: 1.7rem;
  text-align: center;
}

.paymentSummary {
  padding: 1.5rem 1rem;
  margin-bottom: 1.7rem;
  border-radius: 0.6rem;
  box-shadow: 0 0 0.75rem 0 rgb($black-color, 0.1);
  background-color: $white-color;
  font-family: Montserrat, sans-serif;

  .summaryItem {
    display: flex;

    p {
      margin-right: 0.5rem;
    }
  }
}
