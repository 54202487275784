@import "../../../assets/styles/theme";

.modalWrapper {
  .closeModalContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .btnClose {
      background-color: $white-color;
      color: rgba($black-color, 0.4);
    }
  }

  .modalContent {
    max-height: 30rem;
    overflow-y: auto;
  }
}
