@import "../../../assets/styles/theme";

.sectionContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;

  .content {
    display: flex;
    flex: 1;

    .filtersContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &.mentorFiltersContainer {
        padding: 4rem 0.75rem 1rem 4.75rem;
      }
    }
  }
}

.listContainer {
  position: relative;
}
