@import "../../../../../assets/styles/theme";

.subjectTestCardContainer {
  margin-bottom: 1rem;

  .subjectTestCard {
    min-height: 30rem;
    padding: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    transition: 0.3s;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }

    .subjectTestLogo {
      width: 100%;
      padding-top: 55%;
      margin-bottom: 1.7rem;
      background-color: transparent;
      position: relative;
      background-size: 95%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .priceInfo {
      font-family: 'Montserrat', sans-serif;
      font-size: $xm-font-size;
      font-weight: 600;
      line-height: 1.14;
      letter-spacing: 0.44px;
      color: $info-color;
      margin: 2.7rem 0 1.4rem 0;
    }

    .details {
      padding: 0 1rem;

      .name {
        font-family: Montserrat, sans-serif;
        font-size: $base-m-font-size;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0.08rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 1rem;
        color: $black-color;

        &:hover {
          color: $info-color;
          cursor: pointer;
        }
      }
    }
  }
}
