@import "../../../assets/styles/theme";

.mentorsGrid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 1rem;
}


@media (max-width: $breakpoint-xsmall) {
  .mentorsGrid {
    grid-template-columns: auto;
  }
}
