@import "../../../../assets/styles/theme";


.schoolDetails {
  font-family: 'Montserrat', sans-serif;

  .detailsHeader {
    display: flex;
    justify-content: space-between;
    font-size: $ml-font-size; // $xxs-font-size;

    h1 {
      margin-bottom: 0.65rem;
      font-weight: 600;
      color: $info-color;

      .subject {
        text-shadow: -0.8rem 1rem 2.7rem rgba(0, 0, 0, 0.15);
        font-size: $xxxl-font-size;
        font-weight: 500;
        margin-bottom: 2.2rem;
        color: $black-color;
      }
    }

    .schoolRanking {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: end;

      .number {
        font-weight: 600;
        margin-bottom: 0.03rem;
        color: $black-color;
      }

      .label {
        font-size: $base-m-font-size; // $xxxs-font-size;
        font-weight: 500;
        color: $black-color;
      }
    }
  }

  .schoolDescriptionWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .schoolDescription {
      display: flex;
      align-items: center;

      i, .description {
        font-family: 'Montserrat', sans-serif;
        font-size: $base-ms-font-size;
        font-weight: 500;
        color: $black-color;
        letter-spacing: 0.03rem;
      }

      i {
        margin-right: 0.6rem;
      }

      .divider {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        margin: 0 1.5rem;
        background-color: $datepicker-header-color;
      }
    }
  }
}

@media (max-width: $breakpoint-xlarge) {
  .schoolDetails {
    .detailsHeader {
      font-size:  $xxs-font-size;

      p {
        &.subject {
          text-shadow: -0.39rem 0.5rem 1.35rem rgba(0, 0, 0, 0.15);
          font-size: $m-font-size;
          margin-bottom: 1.1rem;
        }
      }

      .schoolRanking {
        .label {
          font-size: $xxxs-font-size;
        }
      }
    }

    .schoolDescriptionWrapper {
      .schoolDescription {
        i, .description {
          font-size: $xxxs-font-size;
        }

        i {
          margin-right: 0.3rem;
        }

        .divider {
          width: 0.3rem;
          height: 0.3rem;
          margin: 0 0.75rem;
        }
      }
    }
  }
}
