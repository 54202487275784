@import "../../../../assets/styles/theme";

.videoWrapper {
  display: flex;
  flex:1;
  margin-bottom: 1rem;
  .videoContent {
      display: flex;
      flex: 1;
      max-width: 100%;
  }
}


@media (max-width: $breakpoint-xlarge) {

}
