@import "../../../../assets/styles/theme";

.sectionFooter {
  padding: 2rem 0;
  font-size: $xs-font-size;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 1.5rem;
}
