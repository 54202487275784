@import "../../../../assets/styles/theme";


.modalWrapper {
  border-radius: 0.4rem;
  max-height: 60vh;
  overflow-y: scroll;

  .cardWrapper {
    max-width: 62vw;


    .modalHeader {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: transparent;
        color: $action-btn-color;
        font-weight: 600;
      }
    }

    .selectTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      color: $black-color;

      h2 {
        text-shadow: -0.78rem 1.04rem 2.7rem rgba($black-color, 0.15);
        font-size: $xm-font-size;
        line-height: 1.4;
        letter-spacing: 0.063rem;
        margin-bottom: 1rem;
      }

      p {
        text-align: center;
        font-size: $base-ms-font-size;
        line-height: 1.87;
        letter-spacing: 0.15rem;
      }
    }

    .selectContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
