@import "../../../assets/styles/theme";

.cartTotal {
  padding: 4rem;
  margin-left: 1rem;
  border-radius: 0.6rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;

  .title {
    text-shadow: -0.78rem 1rem 2.7rem rgba(0, 0, 0, 0.15);
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: 0.09rem;
    margin-bottom: 1.5rem;
    font-family: Montserrat, sans-serif;
    font-size: $xm-font-size;
    font-weight: 600;
    color: $black-color;
  }

  .price {
    margin-bottom: 2.9rem;
    font-family: Montserrat, sans-serif;
    font-size: $xxxl-font-size;
    font-weight: 600;
    color: $info-color;
  }
}
