@import "../../../assets/styles/theme";


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scheduleWrapper {
  width: 100%;

  .schedulePlaceholder {
    width: 100%;
    height: 17rem;
    border-radius: 3px;
    border: dashed 0.2rem #d4d4d4;
    background-color: #f9fcfd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0 5rem;
      text-shadow: -0.39rem 0.52rem 1.35rem rgba(0, 0, 0, 0.15);
      font-family: Montserrat, sans-serif;
      font-size: $m-font-size;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 0.025rem;
      color: $black-color;
      text-align: center;
    }
  }
}
