@import "../../../../assets/styles/theme";

.testCard {
    min-height: 28rem;
    padding: 2.3rem 2rem;
    border-radius: 0.6rem;
    box-shadow: -1.2rem 1.6rem 2.7rem 0 rgba(0, 0, 0, 0.05);
    mix-blend-mode: multiply;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    transition: 0.3s;
    position: relative;
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, #252525, transparent);
        content: '';
        z-index: 50;
    }

    .testDetails {
        width: 100%;
        z-index: 100;

        .testTitle {
            margin-bottom: 1.4rem;
            font-family: Montserrat, sans-serif;
            font-size: $base-m-font-size;
            font-weight: 600;
            color: $white-color;
            .titleLink {
                color: $white-color;
                text-decoration: none;
            }
        }

        .details {
            .testCompletion {
                display: flex;
                align-items: center;

                .testStatus {
                    width: 3.4rem;
                    height: 3.4rem;
                    padding: 1rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1rem;
                    background-color: $danger-color;

                    i {
                        color: $white-color;
                    }

                    &.passed {
                        background-color: $success-color;
                    }
                }

                .testPoints {
                    display: flex;
                    align-items: baseline;
                    flex-wrap: wrap;

                    .points {
                        margin-right: 0.5rem;
                        font-family: Montserrat, sans-serif;
                        font-size: $xll-font-size;
                        font-weight: 600;
                        color: $white-color;
                    }

                    .maxPoints {
                        font-family: Montserrat, sans-serif;
                        font-size: $small-font-size;
                        font-weight: 500;
                        color: $white-color;
                    }
                }
            }

            .testBtn {
                display: none;
                justify-content: center;
                align-items: center;
                padding: 1.2rem 1.5rem;
                border-radius: 0.4rem;
                box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
                background-color: $info-color;
                margin-top: 1.8rem;
                color: $white-color;
                // animation: fade_in_show 0.5s;
                &.testBtnLink {
                    text-decoration: none;
                    font-size: $base-font-size;
                }
                i {
                    margin-left: 1rem;
                }

                &.visible {
                    display: inline-flex;
                }
            }

            .testDescription {
                display: flex;
                flex: 1;

                .price {
                    display: flex;
                    flex: 1;
                    flex-direction: column;

                    .priceValue {
                        font-family: Montserrat, sans-serif;
                        font-size: $m-font-size;
                        font-weight: 600;
                        color: $white-color;
                        margin-bottom: 0.3rem;
                    }

                    .priceLabel {
                        font-family: Montserrat, sans-serif;
                        font-size: $small-font-size;
                        font-weight: 500;
                        color: $gray-text-color;
                    }
                }

                .reviews {
                    border-top: 0.3rem $reviews-border-color solid;
                    padding-top: 1rem;
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                    align-items: center;

                    span {
                        color: $white-color;
                        margin-right: 0.6rem;
                    }

                    .reviewCount {
                        font-family: Montserrat, sans-serif;
                        font-size: $small-font-size;
                        font-weight: 500;
                        text-align: left;
                        color: $gray-text-color;
                    }
                }
            }
        }
    }

    &:hover {
        transform: scale(1.1);
        transition: 0.3s;
        cursor: pointer;
    }

    &.complete:hover {
        .testDetails {
            .details {
                .testBtn {
                    display: inline-flex;
                    animation: fade_in_show 0.5s;
                }
            }
        }
    }
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
