@import "../../../assets/styles/theme";

.schoolProfileSection {
  background: url("../../../assets/images/patches.png") no-repeat right 45rem;
}

.schoolBg {
  background-image: url('../../../assets/images/school_logo_background.jpg');
  background-color: $base-background;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 3rem;
  border-radius: 0.6rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  .schoolLogo {
    max-width: 22rem;
    max-height: 22rem;
    border-radius: 50%;
    display: flex;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 11rem;
    background-color: $white-color;
  }
}

.mentorTitle {
  margin-bottom: 2.4rem;
}

.requirementsWrapper {

    ul {
      list-style: none;
      li {
        margin: 12px 0 0 7px;
        font-family: 'Montserrat', sans-serif;
        font-size: $base-font-size;
        font-weight: 500;
        color: #000;

        &:before {
          content: "\2022";
          color: $info-color;
          font-weight: bold;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
        }
      }
    }

  .requirementName {
    font-weight: 600;
  }
}

.sidebarCard {
  .sidebarCardTitle {
    margin-bottom: 0.7rem;
  }

  .sidebarCardInfo {
    text-shadow: -3.9px 5.2px 13.5px rgba(0, 0, 0, 0.15);
    font-family: 'Montserrat', sans-serif;
    font-size: $xxxl-font-size; //$m-font-size;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.44px;
    color: $info-color;

    &.sidebarCardInfoDeadline {
      color: $danger-color;
    }
  }
}

.schoolProfileSideBar {
  .mentorCardHeader {
    display: flex;
    flex-direction: column;
    text-align: center;

    .subtitle {
      font-family: 'Montserrat', sans-serif;
      font-size: $base-m-font-size;
      font-weight: 500;
      color: $black-color;
      margin-bottom: 1.3rem;
    }
  }

  .mentorDetails {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

.mapBg {
  width: 100%;
  height: 40rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: $breakpoint-xlarge) {

}
