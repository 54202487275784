@import "../../../../assets/styles/theme";

.actionButtonsWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 3.2rem;

  .actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    &[disabled] {
      opacity: 0.6;
    }

    &[data-descr] {
      position: relative;

      &:hover,
      &:focus {
        &::after {
          content: attr(data-descr);
          position: absolute;
          top: -5.5rem;
          padding: 1.3rem 3.2rem;
          border: 0.2rem $info-color solid;
          background-color: $white-color;
          border-radius: 0.4rem;
          color: #000000;
          font-weight: 600;
          font-size: $small-base-font-size;
          white-space: nowrap;
          z-index: 1;
        }

        &::before {
          content: "";
          width: 0.75rem;
          height: 0.75rem;
          background-color: $white-color;
          transform: rotate(45deg);
          position: absolute;
          top: -1.4rem;
          border-right: 0.2rem solid $info-color;
          border-bottom: 0.2rem solid $info-color;
          z-index: 2;
        }
      }
    }

    .buttonIcon {
      font-size: $l-font-size;
      color: $gray-text-color;
      width: 2rem;
      height: 2rem;
      background-size: contain;

      &:not(:first-child) {
        margin-left: 1.8rem;
      }

      &.activeStarIcon {
        background: transparent url("../../../../assets/images/active_star.svg") no-repeat center;
        background-size: contain;
      }

      &.inactiveStarIcon {
        background: transparent url("../../../../assets/images/star_empty_gray.png") no-repeat center;
        background-size: cover;
      }

      &.activeHeartIcon {
        background: transparent url("../../../../assets/images/heart_on.svg") no-repeat center;
        background-size: contain;

      }

      &.inactiveHeartIcon {
        background: transparent url("../../../../assets/images/heart_off.svg") no-repeat center;
        background-size: contain;
      }
    }

    .loaderIcon {
      width: 2.2rem;
      height: 2.2rem;
      background-size: contain;
      background-repeat: no-repeat;
      background: transparent url("../../../../assets/images/action_button_spinner.svg");
    }
  }

  .closeButton {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $school-card-close-button-color;
    color: $white-color;

    &[disabled] {
      opacity: 0.6;
    }

    &.loaderActive {
      background-color: $white-color;
      border: thin solid $border-color;

      .removeLoaderIcon {
        background: transparent url("../../../../assets/images/action_button_spinner.svg");
        width: 2.2rem;
        height: 2.2rem;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &[data-descr] {
      &:hover,
      &:focus {
        &::after {
          content: attr(data-descr);
          position: absolute;
          top: -5.5rem;
          padding: 1.3rem 3.2rem;
          border: 0.2rem $info-color solid;
          background-color: $white-color;
          border-radius: 0.4rem;
          color: $black-color;
          font-weight: 600;
          font-size: $small-base-font-size;
          white-space: nowrap;
          z-index: 1;
        }

        &::before {
          content: "";
          width: 0.75rem;
          height: 0.75rem;
          background-color: $white-color;
          transform: rotate(45deg);
          position: absolute;
          top: -1.4rem;
          border-right: 0.2rem solid $info-color;
          border-bottom: 0.2rem solid $info-color;
          z-index: 2;
        }
      }
    }
  }
}
