@import "../../../../../../assets/styles/theme";


.mentorItem {
  background-color: $white-color;
  display: flex;
  justify-content: space-between;
  padding: 1.8rem 2rem;
  width: 100%;

  .itemDetails {
    margin-right: 1rem;
    display: flex;
    flex: 2;


    .itemImgContainer {
      display: flex;
      justify-content: center;
      height: 14rem;
      width: 14rem;
      border-radius: 50%;
      border: thin solid $border-color;
      overflow: hidden;
      margin-right: 1rem;
    }

    .mentorDetails {
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      color: $black-color;
      letter-spacing: normal;
      display: flex;
      flex-direction: column;
      flex: 1;

      h3 {
        font-size: $l-font-size;
        font-weight: 600;
        margin-top: 1rem;
        letter-spacing: normal;
      }

      .schoolDetails {
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin: 1.5rem 0;

        .realm {
          font-weight: 600;
          text-transform: uppercase;
          color: $info-color;
        }
      }

      .languages {
        margin-bottom: 1rem;
      }

      .description {
        font-size: $small-font-size;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.024rem;
      }
    }
  }

  .itemPrice {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    .name {
      font-family: Montserrat, sans-serif;
      font-size: $base-m-font-size;
      color: $black-color;
      font-weight: 600;
      margin-bottom: 1rem;
      text-shadow: -0.39rem 0.52rem 1.35rem rgba($black-color, 0.15);
      line-height: 1.14;
    }

    .amount, .currency {
      font-family: Montserrat, sans-serif;
      font-size: $m-font-size;
      font-weight: 600;
      margin-bottom: 1rem;
      text-shadow: -0.39rem 0.52rem 1.35rem rgba($black-color, 0.15);
      line-height: 1.14;
      color: $info-color;
    }

    .amount {
      margin-right: 0.6rem;
    }
  }
}
