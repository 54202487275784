@import "../../../../assets/styles/theme";

.serviceTerms {
  display: flex;
  flex-direction: column;
  border-right: 0.1rem solid $input-border-color;
  padding: 3rem 4rem;

  .title {
    margin-bottom: 0.9rem;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: bold;
    color: $black-color;
    text-transform: uppercase;
  }

  .eventsContainer {
    position: relative;

    .eventDate {
      padding: 1.2rem 1.2rem 1.2rem 1.6rem;
      border-radius: 0.4rem;
      background-color: $datepicker-filter;
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 600;
      color: $black-color;
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;

      p {
        margin-right: 0.6rem;
        font-weight: 600;
      }

      button {
        padding: 0.5rem 1rem;
        background-color: transparent;

        &:first-of-type {
          margin-right: 0.5rem;
        }

        &:hover, &:active {
          color: $info-color;
        }
      }
    }

    .selectEvent {
      display: flex;
      align-items: center;

      .freeEvent {
        padding: 1.3rem 1.6rem;
        border-radius: 0.4rem;
        background-color: $datepicker-filter;
        font-family: Montserrat, sans-serif;
        font-size: $base-font-size;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: 0.5rem;
        &:hover, &:active {
            color: $white-color;
            background-color: $info-color;
        }
      }
    }
  }

  &.serviceModal {
    border: none;
  }
}
@media (max-width: $breakpoint-xxlarge) {
  .serviceTerms {
    border: none;
  }
}
