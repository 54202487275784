@import "../../../assets/styles/theme";


.applicationProgress {
  margin-top: 3rem;
  width: 100%;
  position: relative;

  .steps {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .step {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      background-color: transparent;
      margin: 2.2% 2.5%;

      .stepDetails {
        width: 100%;
        height: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          cursor: pointer;

          .title {
            h3 {
              color: $primary-color;
            }
          }

          .detailsContainer {
            .details {
              display: flex;
            }

            img {
              display: none;
            }
          }
        }

        .title {
          margin-top: 16%;
          padding: 0 6%;
          height: 30%;
          position: relative;

          h3 {
            font-family: Montserrat, sans-serif;
            font-size: 0.8vw;
            font-weight: 600;
            line-height: 1.29;
            letter-spacing: 0.28px;
            text-align: center;
            color: $black-color;
          }

          .stepComplete {
            display: none;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            background-color: $success-color;
            position: absolute;
            top: -3rem;
            right: 0;


            i {
              color: $white-color;
            }
          }
        }

        .detailsContainer {
          flex: 1;
          .details {
            display: none;
            padding: 0 7%;
            margin-top: 5%;
            color: $black-color;
            font-family: Montserrat, sans-serif;
            text-align: center;
            font-size: 0.6vw;
            letter-spacing: 0.028rem;
          }

          img {
            margin-top: 5%;
            height: 70%;
          }
        }
      }

      &:nth-child(even) {
        align-items: flex-start;
      }

      &.enabled {
        .stepDetails {
          .title {
            .stepComplete {
              display: flex;
            }
          }
        }
      }
    }
  }

  .bg {
    width: 100%;
    padding-bottom: 33.637%;
    background-image: url("../../../assets/images/step_direction.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.school {
    .bg {
      background-image: url("../../../assets/images/step_school.png");
    }
  }

  &.application {
    .bg {
      background-image: url("../../../assets/images/step_application.png");
    }
  }

  &.documents {
    .bg {
      background-image: url("../../../assets/images/step_documents.png");
    }
  }

  &.study {
    .bg {
      background-image: url("../../../assets/images/step_study.png");
    }
  }
}

@media only screen and (max-width: $breakpoint-xxlarge) {
  .applicationProgress {
    .steps {
      .step {
        .stepDetails {
          .title {
            margin-top: 19%;

            h3 {
              font-size: 0.9vw;
            }
          }

          .detailsContainer {
            .details {
              font-size: 1.1vw;
            }

            img {
              margin-top: 8%;
              height: 55%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .applicationProgress {
    .steps {
      .step {
        .stepDetails {
          .title {
            margin-top: 16%;

            h3 {
              font-size: 1.4vw;
            }
          }

          .detailsContainer {
            .details {
              font-size: 1.1vw;
            }
          }
        }
      }
    }
  }
}

