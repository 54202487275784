@import "../../../assets/styles/theme";

.selectedFilters {
  display: flex;
  align-items: center;
  margin: 1rem 0 2.4rem;

  .label {
    font-family: 'Montserrat', sans-serif;
    font-size: $base-font-size;
    font-weight: bold;
    color: $black-color;
  }

  .filtersWrapper {
    display: flex;
    flex-wrap: wrap;

    button {
      background-color: $white-color;
      margin-left: 1rem;
      font-family: 'Montserrat', sans-serif;
      font-size: $base-font-size;
      font-weight: 600;
      letter-spacing: 0.042rem;
      color: rgba(5, 7, 8, 0.5);
    }
  }
}
