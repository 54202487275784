@import "../../../assets/styles/theme";


.cartCard {
  border-radius: 0.6rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  transition: 0.3s;
  display: flex;
  margin-bottom: 1.8rem;
  position: relative;

  .btnRemove {
    position: absolute;
    right: -1.25rem;
    top: -1.25rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $school-card-close-button-color;
    color: $white-color;
  }
}
