.insurance-data-form {
  color: $input-text-color-gray;

  .row {
    &.multiple-controls-row {
      display: flex;
      gap: 1.9rem;

      .form-control {
        border: none;
        flex: 1;
        margin-bottom: 2.4rem;

        .react-datepicker-host {
          width: 100%;
        }

        .error-block {
          position: absolute;
          right: 4rem;
          bottom: 3.5rem;
          align-items: center;
          flex-direction: row;

          .error-element {
            display: none;
          }

          &:before {
            content: "!";
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-weight: 600;
            border: 0.2rem solid $error-block-color;
            color: $error-block-color;
            height: 1.6rem;
            width: 1.6rem;
          }
        }

        &.required {
          .form-label {
            position: relative;
            margin-bottom: 1rem;

            &::after {
              content: "*";
              color: $info-color;
              right: -1rem;
              top: 0;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }

  .checkbox-container {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin: 0;

    .checkbox-wrapper {
      width: 2.1rem;
      height: 2.1rem;
      margin: 0;
      padding: 0;

      .checkbox-checkmark {
        top: 0;
        bottom: 0;

        &:after {
        }
      }
    }

    .form-label {
      margin: 0 0 0 2rem;
    }
  }

  .fieldset {
    margin-bottom: 0.5rem;

    .fieldset-legend {
      margin: 0.5rem 0;
      padding: 0.75rem 1.5rem;
      font-weight: 700;
      color: $black-color;
      font-size: $base-ms-font-size;
    }

    .default-fieldset {
      margin: 0 0 1rem;

      &.maturity-fieldset {
        .checkbox-fieldset-container {
          flex-basis: 50%;
        }
      }

      .checkbox-fieldset-container {
        flex-direction: row;
        flex-basis: 33.3333%;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.5rem;
        margin: 0;
        background-color: $white-color;
        border: thin solid $auth-form-background;

        .form-label {
          margin: 0;
          font-size: $small-base-font-size;
          letter-spacing: 0.039rem;
        }
      }
    }
  }

  .input {
    display: flex;
    flex: 1;
    height: 5rem;
    padding: 0.5rem 1.5rem;
    border-radius: $button-border-radius;
    border: 0.2rem solid $input-border-color;
    font-weight: 500;
    background: $white-color;
    box-sizing: border-box;
    font-family: $montserrat-font-family;
    font-size: $base-ms-font-size;
    line-height: 3.4;
    letter-spacing: 0.09rem;
    color: $black-color;

    &::placeholder {
      font-family: $montserrat-font-family;
      font-size: $base-ms-font-size;
      font-weight: 500;
      line-height: 3.4;
      letter-spacing: 0.09rem;
      color: rgba($black-color, 0.6);
    }

    &[type="file"] {
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      color: transparent;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      &::before {
        content: "Dodaj zdjęcie z dysku";
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
        letter-spacing: 0.014rem;
        color: $info-color;
        text-decoration: underline;
        font-weight: 500;
        font-size: $small-base-font-size;
      }
    }
  }

  .custom-number {
    justify-content: center;

    .custom-number-input-wrapper {
      margin: 0;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }


  .form-row {
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;

    &.required {
      position: relative;

      .form-label {
        color: $black-color;
        position: relative;

        &::after {
          content: "*";
          color: $info-color;
          right: -1rem;
          top: 0;
          margin-left: 0.5rem;
        }
      }

      .error-block {
        position: absolute;
        right: 4rem;
        bottom: 3.5rem;
        align-items: center;
        flex-direction: row;

        &:before {
          content: "!";
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          border: 0.2rem solid $primary-color;
          color: $primary-color;
          font-weight: 600;
        }

        .error-element {
          display: none;
        }
      }
    }

    &.invalid {
      .input {
        border: 0.2rem solid $error-block-color;
      }

      .error-block {
        &:before {
          border: 0.2rem solid $error-block-color;
          color: $error-block-color;
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }

    &.study-year-control {
      flex-direction: row-reverse;
      padding: 0;
    }

    &.no-option-checkbox {
      justify-content: flex-end;

      .checkbox-container {
        width: 50%;
      }
    }

    .basic-single {
      flex: 1;

      .select__indicator-separator {
        width: 0.2rem;
      }

      .select__single-value {
        font-family: $montserrat-font-family;
        font-size: $base-ms-font-size;
        line-height: 3.4;
        letter-spacing: 0.09rem;
        color: $black-color;
        font-weight: 500;
      }

      .select__menu {
        .select__option {
          font-family: $montserrat-font-family;
          font-size: $small-base-font-size;
          font-weight: 600;
          line-height: 1.38;
          letter-spacing: 0.078rem;
          color: $black-color;

          &:nth-child(odd) {
            background-color: $multi-select-option-background;

            &:hover {
              background-color: $select-option-background;
            }
          }
        }
      }

      .select__placeholder {
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.4;
        letter-spacing: 0.9px;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .form-label {
      flex: 1;
      margin: 0;

      &[for="insuranceDuration"] {
        align-self: flex-start;
        margin-top: 1rem;
      }
    }

    .input-container {
      flex: 1;
    }

    .basic-multi-select {
      flex: 1;

      .select__control {
        border-radius: $button-border-radius;
        border: 0.2rem solid $input-border-color;
      }
    }

    .textarea-container {
      flex: 1;

      textarea {
        padding: 0.5rem 1.5rem;
        font-weight: 500;
        resize: vertical;
        border-radius: $button-border-radius;
        border: 0.2rem solid $input-border-color;
      }
    }

    .duration-radio-control {
      flex: 1;

      .duration-radio-container {
        .radio-control {
          justify-content: space-between;

          .radio-wrapper {
            .radio-label {
              margin: 0 0 0.3rem;
              padding: 0.9rem 1.2rem;
              border: 0.2rem solid $input-border-color;
              border-radius: 0.4rem;
              color: rgba(0, 0, 0, 0.6);
              background: $white-color;
              font-size: 1.4rem;
              font-weight: 500;
              letter-spacing: normal;

              &:hover {
                color: $black-color;
                border: 0.2rem solid $primary-color;
              }
            }

            .radio-input:checked ~ .radio-label {
              background: $info-color;
              color: $white-color;
              border: 0.2rem solid $info-color;
            }
          }
        }
      }

      .custom-duration-wrapper {
        display: flex;
        align-items: center;

        .custom-number-input-wrapper {
          margin-right: 1rem;
        }

        .custom-duration-label {
          font-family: $montserrat-font-family;
          font-size: $base-font-size;
          font-weight: 500;
          line-height: 3.64;
          letter-spacing: 0.084rem;
          color: $black-color;
        }
      }
    }

    .radio-control {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .radio-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .radio-input,
        .radio-checkmark {
          display: none;
        }

        .radio-label {
          margin: 0 0.3rem 0.3rem 0;
          padding: 0.9rem 1.2rem;
          border: 0.2rem solid $input-border-color;
          border-radius: 2rem;
          color: $input-text-color-gray;
          background: $white-color;
          font-size: $small-base-font-size;
          font-weight: 500;
          white-space: nowrap;
          letter-spacing: normal;

          &:hover {
            cursor: pointer;
            border: 0.2rem solid $primary-color;
            color: $black-color;
          }
        }

        .radio-input:checked ~ .radio-label {
          background: $info-color;
          color: $white-color;
          border: 0.2rem solid $info-color;
        }
      }
    }
  }

  .react-datepicker-host {
    flex: 1;

    .react-datepicker__header__dropdown {
      display: flex;
      justify-content: center;

      .react-datepicker__month-dropdown-container .react-datepicker__month-select,
      .react-datepicker__year-dropdown-container .react-datepicker__year-select {
        height: 2rem;
        cursor: pointer;
        font-family: Montserrat, sans-serif;

        &:focus {
          outline: none;
        }
      }
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          border-radius: $button-border-radius;
          border: 0.2rem solid $input-border-color;
          font-family: $montserrat-font-family;
          font-size: $base-ms-font-size;
          line-height: 3.4;
          letter-spacing: 0.09rem;
          color: $black-color;
          font-weight: 500;

          &::placeholder {
            font-family: $montserrat-font-family;
            font-size: $base-ms-font-size;
            font-weight: 500;
            line-height: 3.4;
            letter-spacing: 0.09rem;
            color: rgba($black-color, 0.6);
          }
        }
      }
    }

    .react-datepicker__aria-live {
      display: none;
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container:after {
        padding-left: 3rem;
        top: calc(50% - 1.5rem);
        right: 0.5rem;
        height: 3rem;
        border-left: 0.2rem solid $input-border-color;
      }
    }

    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        .react-datepicker {
          .react-datepicker__header {
            .react-datepicker__current-month {
              text-shadow: 8.9px 9.5px 27px rgba(0, 0, 0, 0.15);
              font-family: $montserrat-font-family;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.86;
              letter-spacing: 0.7px;
              text-align: right;
              color: #444;
            }
          }
        }
      }
    }
  }

  .button-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    margin-top: 4rem;

    .btn {
      box-shadow: 0.68rem 0.73rem 2.7rem 0 rgba($black-color, 0.1);

      &.btn-back {
        box-shadow: none;
      }
    }

    &.single-button-container {
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: $breakpoint-small) {
  .insurance-data-form {
    .form-row {
      flex-direction: column;
      align-items: flex-start;

      .form-label {
        margin-bottom: 0.7rem;
      }

      .duration-radio-control {
        width: 100%;
      }

      .radio-control {
        justify-content: flex-start;
        .radio-wrapper {
          .radio-label {
            margin-bottom: 0.9rem;
          }
        }
      }

      .react-datepicker-host {
        width: 100%;
      }
    }
  }
}
