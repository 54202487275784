.auth-page {
  .auth-content {
    background: url('../images/background_register.png') no-repeat bottom center;
    background-size: cover;
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .auth-page {
    .auth-content {
      //background-image: url('../images/background_register.png');
    }
  }
}