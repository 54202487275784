.nav-link {
    &.active, &:hover {
        .nav-link-icon {
            .menu-icon {
                background-color: $black-color;
            }
        }
    }
    .nav-link-icon {
        .menu-icon {
            width: 2rem;
            height: 2rem;
            mask-size: contain;
            mask-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white-color;
            &.dashboard {
                mask-image: url(../images/dashboard.svg);
            }
            &.diagnostics {
                mask-image: url(../images/diagnostics.svg);
            }
            &.field-of-study-search {
                mask-image: url(../images/field_of_study_search.svg);
            }
            &.mentors {
                mask-image: url(../images/mentors.svg);
            }
            &.tutors {
                mask-image: url(../images/tutors.svg);
            }
            &.application {
                mask-image: url(../images/application.svg);
            }
            &.contents {
                mask-image: url(../images/applicant_content.svg);
            }
            &.marketplace {
                mask-image: url(../images/marketplace.svg);
            }
            &.briefcase {
                mask-image: url(../images/briefcase.svg);
            }
            &:hover {
                background-color: $black-color;
            }
        }
    }
}
