@import "../../assets/styles/theme";


.dashboard {
  background: url("../../assets/images/patches.png") no-repeat right 35rem;

  .btnWrapper {
    margin-top: 2rem;
  }
}
