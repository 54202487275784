@import "../../../assets/styles/theme";

.modalWrapper {
  .closeModalContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .btnClose {
      background-color: $white-color;
      color: rgba($black-color, 0.4);
    }
  }

  .modalHeader {
    line-height: 1.4;
  }

  .description {
    margin: 1.4rem 0;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 1.69;
    letter-spacing: 0.033rem;
    color: $black-color;
  }
}

.modalContent {
  background-color: white;
}
