@import "../../assets/styles/theme";

.fieldOfStudySection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 2rem 3rem 0 3rem;
  background: url("../../assets/images/patches.png") no-repeat top right;
  flex: 1;
}
