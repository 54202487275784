@import "../../../../../assets/styles/theme";

.consultationPackageWrapper {
  display: flex;
  flex-direction: column;
  margin: 0.9rem 0;

  &[open] {
    .consultationPackageSummary {
      border: solid $info-color 0.3rem;
      border-radius: 0.6rem;

      &::after {
        transform: rotate(-90deg);
      }

      &:focus {
        outline: none;
      }
    }
   }

  &:hover {
    .consultationPackageSummary {
      border: solid $info-color 0.3rem;
      border-radius: 0.6rem;
    }
  }

  .packageSubmitButton {
    padding: 1.4rem 3rem 1.4rem 2.2rem;
    border-radius: 0.4rem;
    box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);
    margin: 0 1rem;
    font-weight: 500;
    align-self: flex-end;
  }

  .consultationPackageSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 3rem;
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
    border: solid transparent 0.3rem;

    .title {
      display: flex;
      flex: 1;
      word-break: break-word;
    }

    .infoPart {
      display: flex;
      flex: 2;
      justify-content: flex-end;
      align-items: center;
    }

    &::after {
      content: url(../../../../../assets/images/arrow_down.png);
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus {
      outline: none;
    }
  }

  .consultationPackageDetailsWrapper {
    display: flex;
    flex-direction: column;
    padding: 2.8rem 3rem;

    .packageDetailsTitle {
      margin-bottom: 2rem;
      font-size: $base-m-font-size;
      letter-spacing: 0.02rem;
      font-weight: 600;
    }

    .consultationPackageDetails {
      display: flex;
      justify-content: space-between;

      .packageContents {
        display: flex;
        flex-direction: column;
      }

      .packageInfoWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .lessonInfoWrapper {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;

          .lessonInfoContainer {
            display: flex;

            .lessonInfoIcon {
              display: flex;
              padding: 1.5rem;
              background-size: cover;
              background-repeat: no-repeat;
              max-width: 3rem;
              max-height: 3rem;

              &.duration {
                background-image: url(../../../../../assets/images/lesson_duration.png);
              }

              &.quantity {
                background-image: url(../../../../../assets/images/lesson_quantity.png);
              }
            }

            .lessonInfoText {
              display: flex;
              flex: 1;
              font-size: $small-base-font-size;
              font-weight: 500;
              letter-spacing: 0.033rem;
              color: $black-color;
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}

.priceBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($info-color, 0.2);
  color: $info-color;
  border-radius: 0.2rem;
  font-weight: 600;
  padding: 0.9rem 1.9rem 0.8rem 2rem;
  margin: 0 1rem;
  letter-spacing: 0.019rem;
  min-width: 10rem;

  .amount {
    margin-right: 0.5rem;
  }

  &.discounted {
    color: $black-color;
    background: linear-gradient(
      to top right,
      #fff calc(50% - 0.2rem),
      rgba($school-card-close-button-color, 0.4),
      #fff calc(50% + 0.2rem)
    );
    opacity: 0.5;
    padding: 0.7rem 0.1rem 0.7rem 0.4rem;
    min-width: 5rem;

    .amount {
      margin-right: 0.5rem;
    }

    &::after {
      content: "";
      border-bottom: 1px solid $black-color;
      transform: rotate(45deg);
    }
  }
}

// .consultationPackageSummary {
//   display: flex;
//   position: relative;
//   pointer-events: none;
// }
//   .consultationPackageSummary::before {
//     display: none;
//     position: absolute;
//     right: 0;
//     top: 0.3rem;
//     content: "";
//     background: url(../../../../assets/images/arrow-right.png);
//     background-size: cover;
//     width: 1.2rem;
//     height: 0.6rem;
//     padding-right: 0.5em;
//   }
// .title {
//   margin-bottom: 3rem;
//   color: #888888;
//   text-transform: uppercase;
//   font-weight: 600;
//   font-style: normal;
//   font-size: 1.4rem;
//   display: flex;
// }

@media (max-width: $breakpoint-xlarge) {
  .consultationPackageWrapper {
    .consultationPackageDetailsWrapper {
      .consultationPackageDetails {
        .packageInfoWrapper {
          margin-top: 2rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-half-xsmall),
       (min-width: $breakpoint-half-xlarge) and (max-width: $breakpoint-xlarge) {
  .consultationPackageWrapper {
    .consultationPackageSummary {
      flex-direction: column;

      .title {
        margin-bottom: 1rem;
        margin-top: 1rem;
        align-self: start;
      }
    }
  }
}
