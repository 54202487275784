@import "../../../../assets/styles/theme";


.calendarDummyImg {
  padding: 30%;
  background-image: url(../../../../assets/images/calendar_dummy_img.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.btnWrapper {
  flex-direction: column;
  .link {
    color: $info-color;
    font-size: $small-base-font-size;
    letter-spacing: 0.014rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }
}


@media (max-width: $breakpoint-xlarge) {

}
