@import "../../assets/styles/theme";


.marketplaceDetails {
  margin-top: 1.7rem;
  background-color: $white-color;

  .marketplaceItem {
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba($black-color, 0.1);
    background-color: $white-color;
    width: 100%;
    min-height: 29rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.tests {
      background: no-repeat center url("../../assets/images/marketplace-tests.png");
    }

    &.application {
      background: no-repeat center url("../../assets/images/marketplace-application.png");
    }

    &.mentors {
      background: no-repeat center url("../../assets/images/marketplace-mentors.png");
    }

    &.tutors {
      background: no-repeat center url("../../assets/images/marketplace-tutors.png");
    }

    &:hover {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($white-color, .9);
      }

      cursor: pointer;

      .itemDescription {
        display: flex;
      }

      .itemTitle {
        display: none;
      }
    }

    .itemDescription {
      flex-direction: column;
      display: none;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;

      p {
        text-align: center;
        margin-bottom: 2rem;
        color: $black-color;
        z-index: 1;
        font-family: Montserrat, sans-serif;
        font-size: $base-font-size;
        font-weight: normal;
        line-height: 1.83;
        letter-spacing: 0.048rem;
      }
    }

    .itemTitle {
      font-family: Montserrat, sans-serif;
      font-size: $ml-font-size;
      font-weight: 500;
      line-height: 2;
      color: $black-color;
      background-color: $white-color;
      padding: 1rem 3rem;
      display: flex;
      align-self: flex-end;
      margin-bottom: 2.8rem;
      box-shadow: -0.78rem 1.04rem 2.7rem 0 rgba($black-color, 0.15);
      letter-spacing: 0.05rem;
    }
  }
}
