@import "../../../../assets/styles/theme";

.iconWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttonIcon {
        font-size: $xl-font-size;
        color: $gray-text-color;
        margin: 0 1rem;
        font-weight: 800;
        &.activeStarIcon {
            background-image: url("../../../../assets/images/active_star.svg");
            width: 3.1rem;
            height: 2.9rem;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &.activeHeartIcon {
            background-image: url("../../../../assets/images/heart_on.svg");
            width: 3.2rem;
            height: 2.8rem;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .counter {
        font-size: $base-font-size;
        color: $black-color;
    }
}
