@import "../../../../assets/styles/theme";

.details {
  display: flex;
  align-items: center;
  border-right: 0.1rem solid $input-border-color;
  padding: 3rem;

  .img {
    border-radius: 50%;
    background-color: $input-border-color;
    display: flex;
    height: 14rem;
    width: 14rem;
    min-width: 14rem;
    border: thin solid $border-color;
    overflow: hidden;
  }

  .mentorDetails {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: Montserrat, sans-serif;
    margin-left: 2rem;
    font-weight: 600;
    color: $black-color;
    .name {
      margin-bottom: 2.4rem;
      font-size: $xm-font-size;
    }

    .studyField {
      margin-bottom: 0.8rem;
      font-size: $base-font-size;
      text-transform: uppercase;
      color: $info-color;
    }

    .school {
      font-size: $base-font-size;
    }
  }
}

@media (max-width: $breakpoint-xxlarge) {
    .details {
      border: none;
	}
}
