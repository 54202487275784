@import "../../../../assets/styles/theme";

.competenceTestCardContainer {
  margin-bottom: 1rem;

  .competenceTestCard {
    padding: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    transition: 0.3s;
    align-items: center;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }

    &.competenceDetailsCard {
      &:hover {
        transform: none;
      }

      .name {
        color: $black-color;
        font-size: $xl-font-size;
        font-weight: 500;
      }

      .details {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .priceDetails {
          display: flex;
          align-items: baseline;

          .priceLabel {
            margin-right: 1rem;
          }
        }
      }
    }

    .competenceTestLogo {
      padding-top: 10%;
      position: relative;
      background: transparent no-repeat center center;
      background-size: contain;
      min-height: 15rem;
    }

    .priceLabel {
      font-family: Montserrat, sans-serif;
      font-size: $small-font-size;
      font-weight: 500;
      letter-spacing: 0.3px;
      margin-top: 1rem;
      color: $black-color;
    }

    .priceInfo {
      font-size: $xm-font-size;
      font-weight: 600;
      line-height: 1.14;
      letter-spacing: 0.44px;
      color: $info-color;
      margin: 1rem 0 1.4rem 0;
    }

    .name {
      font-family: Montserrat, sans-serif;
      font-size: $x-font-size;
      font-weight: 500;
      text-align: left;
      letter-spacing: 0.08rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 3rem;

      .fieldOfStudyName {
        font-size: $x-font-size;
      }

      a {
        color: $black-color;
      }

      &:hover {
        a {
          color: $info-color;
        }
      }
    }

    .details {
      padding: 0 1rem;

      &.modalTest {
        display: flex;
        align-items: flex-end;

        .buttonWrapper {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xlarge) {
  .competenceTestCardContainer {
    .competenceTestCard {
      .details {
        padding: 0 3rem;
      }
    }
  }
}
