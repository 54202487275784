@import "../../../../../assets/styles/theme";

.selectMentorForm {
  width: 100%;
}

.mentorList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;

  .noMentors {
    font-family: Montserrat, sans-serif;
    font-size: $base-m-font-size;
    font-weight: 500;
    line-height: 1.87;
    letter-spacing: 0.15rem;
    color: $black-color;
  }
}
