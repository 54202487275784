@import "../../../assets/styles/theme";

.filterItem {
  margin: 0 0.65rem 0 0.7rem;
  padding: 0.5rem 1.15rem 0.5rem 1.05rem;
  border-radius: 1.6rem;
  border: solid 0.01rem #d3d3d3;
  background-color: $white-color;
  display: flex;

  .filterItemTooltip {
    bottom: 3.5rem;
  }

  p {
    opacity: 0.6;
    font-family: 'Montserrat', sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }

  button {
    opacity: 0.4;
    color: $black-color;
    background-color: $white-color;
    margin-left: 1rem;
  }
}
